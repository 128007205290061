.tooltip {
  position: relative;
}

.tooltip-content {
  position: absolute;
  transform: translateX(-50%);
  border-radius: 12px;
  width: max-content;
  max-width: 300px;

  &:before {
    content: "";
    position: absolute;
  }
}

/* POSITION TOP */
.tooltip-content {
  z-index: 50;
  &[data-placement="top"] {
    bottom: 100%;
    left: 50%;
    margin-bottom: 10px;

    &:before {
      top: 100%;
      left: 50%;
      margin-left: -4px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #000;
    }
  }
  &[data-placement="bottom"] {
    top: 100%;
    left: 50%;
    margin-top: 10px;

    &:before {
      bottom: 100%;
      left: 50%;
      margin-left: -4px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #000;
    }
  }
  &[data-placement="bottom-left"] {
    top: 100%;
    left: 0;
    transform: translateX(10%);
  }

  &[data-placement="bottom-right"] {
    top: 100%;
    right: 0;
    transform: translateX(-10%);
  }

  &[data-placement="top-left"] {
    bottom: 100%;
    right: 0;
    transform: translateX(-10%);
  }

  &[data-placement="top-right"] {
    bottom: 100%;
    left: 0;
    transform: translateX(10%);
  }
}

// .tooltip-button-close {
//   position: absolute;
//   bottom: 100%;
//   right: 100%;
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   background-color: #000;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &:before {
//     content: "\2715";
//     display: block;
//     color: #fff;
//     width: 100%;
//   }
// }

.tooltip-button-close {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "\2715";
    display: block;
    color: #898989;
    width: 100%;
    font-weight: bolder;
  }
}
