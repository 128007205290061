.customSelectSearch {
  input[type="text"]:focus {
    box-shadow: none;
  }
}

.location-node {
  width: 18px;
  height: 18px;
  position: absolute;
  border: 2px solid white;
  border-radius: 50%;

  &[data-location="ha-giang"] {
    @media screen and (min-width: 1280px) {
      top: 50px;
      left: 271px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 40px;
      left: 226px;
    }
  }

  &[data-location="ha-noi"] {
    @media screen and (min-width: 1280px) {
      top: 132px;
      left: 278.5px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 109px;
      left: 232px;
    }
  }

  &[data-location="hoi-an"] {
    @media screen and (min-width: 1280px) {
      top: 325px;
      left: 289px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 269px;
      left: 241.5px;
    }
  }

  &[data-location="da-nang"] {
    @media screen and (min-width: 1280px) {
      top: 342px;
      left: 315px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 283px;
      left: 263.5px;
    }
  }
  &[data-location="nha-trang"] {
    @media screen and (min-width: 1280px) {
      top: 459px;
      left: 327px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 380px;
      left: 275px;
    }
  }

  &[data-location="hcm"] {
    @media screen and (min-width: 1280px) {
      top: 525px;
      left: 218px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 435px;
      left: 183px;
    }
  }

  &[data-location="phu-quoc"] {
    @media screen and (min-width: 1280px) {
      top: 525px;
      left: 107px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: 435px;
      left: 90px;
    }
  }
}

.map-slide-dots {
  @apply absolute bottom-[60px] left-1/2 mt-4 flex w-full -translate-x-1/2 items-center justify-center;

  .slider-dot {
    @apply mr-[5px] h-3 w-3 cursor-pointer rounded-full border border-arrivalhub-orange-800 bg-transparent;
    &.slider-dot-active {
      @apply bg-arrivalhub-orange-800;
    }
  }
}

// .ts_slick_dots_custom {
//   @apply   left-1/2 -translate-x-1/2;

//   bottom: 60px !important;
//   li {
//     @apply mr-[5px] lg:h-5 lg:w-5 cursor-pointer rounded-full border border-arrivalhub-orange-800 bg-white;
//   }
//   :global(.slick-active) {
//     button {
//       &::before {
//       @apply border-arrivalhub-orange-800;
//        border-radius: 50%;
//        width: 20px !important;
//        height: 20px !important;
//        content: " " !important;
//         background-color: #FF8F01!important;
//       }
//     }
//   }
// }
